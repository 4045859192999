
:root {
  --introduction-font-size: 20px;
  --introduction-font-stack: #{$ff-meta-comp-pro-font-stack};
  --introduction-font-style: normal;
  --introduction-font-variant: normal;
  --introduction-font-weight: #{get-ff-meta-comp-pro-weight(regular)};
  --introduction-letter-spacing: 0;
  --introduction-line-height: 1.5em;
  --introduction-margin-bottom: .5em;
  --introduction-margin-top: 1em;

  @include portrait {
    --introduction-font-size: 22px;
    --introduction-line-height: 1.5em;
  }

  @include landscape {
    --introduction-font-size: 24px;
    --introduction-line-height: 1.5em;
  }
}
