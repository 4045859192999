.form-fieldset {
  @include adaptive-margin(45px, 45px);

  background-color: #f3f3f3;
  padding: 1.25rem .9375rem;
  position: relative;

  .form-fieldset__legend {
    @include typography(heading-3, false, false, true);

    display: block;
    width: 100%;
  }
}
