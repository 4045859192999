
.container {
  overflow-x: hidden;
  position: relative;
}

.container--wrapper {
  //
}

.container--header {
  //
}

.container--content {
  //
}

.container--footer {
  //
}

.container--overlays {
  //
}
