
:root {
  --button-font-size: 16px;
  --button-font-stack: #{$ff-meta-comp-pro-font-stack};
  --button-font-style: normal;
  --button-font-variant: normal;
  --button-font-weight: #{get-ff-meta-comp-pro-weight(bold)};
  --button-letter-spacing: 0;
  --button-height: 38px;
  --button-size-large: 40px;
  --button-size-small: 12px;
  --button-line-height: 20px;
  --button-margin-bottom: 0;
  --button-margin-top: 0;

  @include portrait {
    //
  }

  @include landscape {
    //
  }
}
