
@mixin image-header {
  background-color: transparent;
  color: transparent;
  height: calc(100vh - 140px);
  overflow-y: hidden;
  position: relative;
  z-index: 0;

  &.image-header--context {
    @include mobile-only {
      height: calc(100vh - 140px);
    }
  }

  @include landscape {
    height: calc(100vh - 140px);
  }

  .image-header__expand {
    background: $color-white;
    color: $color-blue;
    display: none;
    height: 40px;
    left: auto;
    opacity: 1;
    padding: 12px;
    position: absolute;
    right: 65px;
    top: 25px;
    width: 40px;
    z-index: 500;

    @include landscape {
      display: block;
    }
  }

  .image-header__expand--single {
    right: 25px;
  }

  .image-header__mobile-markers {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -60px;
    width: calc(100vw - 30px);
    z-index: 500;

    .image-header__slide-indicator {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    @include landscape {
      display: none;
    }
  }

  &.image-header__no-image {
    height: 100%;

    .image-header__label {
      position: static;
    }
  }

  .swiper__title-wrapper {
    background-image: linear-gradient(rgba(0, 0, 0, .33) 40%, rgba(0, 0, 0, 0) 100%);
    height: calc(100vh - 140px);
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .swiper__title-grid {
    @include grid-container;

    align-content: center;
    height: 100%;
  }

  .swiper__title {
    @include typography(heading-1);

    color: $color-white;
    font-size: 46px;
    grid-column: column 2/span 10;
    text-align: center;

    @include portrait {
      font-size: 52px;
    }

    @include landscape {
      font-size: 80px;
    }
  }

  .picture--background {
    height: calc(100% - 140px);
  }
}

@mixin swiper {
  height: 100vh;

  @include landscape {
    min-height: 100vh;
  }

  .swiper-wrapper {
    padding: 0;
  }

  .swiper-slide {
    list-style: none;
    opacity: 0;
    transition: opacity 300ms;

    &.swiper-slide-active {
      opacity: 1;
    }
  }
}

@mixin image-header__label {
  bottom: 0;
  left: 0;
  max-width: 90%;
  min-width: 250px;
  padding: 15px;
  position: absolute;
  z-index: 500;

  @include landscape {
    max-width: 60%;
    min-width: 550px;
    padding: 20px 30px;
  }

  .image-header__date,
  .image-header__name,
  .image-header__title {
    word-wrap: break-word;
  }

  .image-header__date {
    @include typography(sub-heading);
  }

  .image-header__name {
    text-transform: uppercase;

    @include typography(sub-heading);
  }

  .image-header__title {
    text-transform: uppercase;

    @include typography(heading-1);
  }

  &.image-header__label--white {
    background: $color-white;
    color: $color-blue;
  }

  &.image-header__label--blue {
    background: $color-blue;
    color: $color-white;
  }
}

.image-header {
  @include image-header;

  .image-header__label {
    @include image-header__label;
  }

  .swiper-container {
    @include swiper;
  }
}
