
.card-list {
  margin: var(--margin) 0;
  position: relative;

  .card-list__grid {
    @include grid-container;
  }

  .card-list__wrapper {
    grid-column: column 1 / span 12;

    @include landscape {
      grid-column: column 2 / span 10;
    }
  }

  .card-list__title {
    @include typography(heading-2);

    margin-bottom: var(--heading-2-margin-bottom);
  }

  .card-list__cards {
    border: 1px solid $color-grey-medium;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding-top: 80px;
    position: relative;

    @include portrait {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .card-list__label {
    @include typography(heading-6);

    background-color: $color-grey-dark;
    color: $color-white;
    font-size: 16px;
    line-height: 22px;
    padding: 10px 15px;
    position: absolute;
    right: 40px;
    top: 0px;
  }

  .card-list__image {
    min-width: 250px;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 43.75%;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .card-list__content {
    padding: 25px;
    position: relative;
    width: 100%;

    .card-list__card-data {
      @include typography(heading-6);

      color: $color-black;
      display: block;
      font-size: 16px;
      line-height: 22px;
      margin-right: 48px;

      @include landscape {
        display: inline-block;
      }
    }

    .card-list__card-data span {
      vertical-align: middle;
    }
  }

  .card-list__card-title,
  .card-list__text {
    color: $color-black;
    font-size: 16px;
    line-height: 22px;
  }

  .card-list__text {
    margin-top: 30px;
  }

  .card-list__card-title {
    @include typography(heading-1);

    font-weight: 200;
    hyphens: auto;
    margin-bottom: 10px;
    word-wrap: break-word;
  }

  .card-list__pagination {
    text-align: center;
  }
}

.card-list__inactive {
  .card-list__content .card-list__card-data,
  .card-list__card-title,
  .card-list__text {
    color: $color-grey-dark;
  }

  .card-list__introduction {
    margin-bottom: 40px;
  }

  .card-list__accordion-button {
    @include typography(button);

    color: $color-blue-dark;
    margin-bottom: 80px;

    .link__icon {
      padding: 0em .6em .3em 0em;
    }

    &[aria-expanded='true'] svg {
      transform: rotateX(180deg);
    }
  }

  .card-list__accordion-wrapper[aria-hidden='true'] {
    display: none;
  }
}
