
.figure {
  position: relative;

  .picture {
    height: 100%;
    width: 100%;
  }

  .figure__caption-grid {
    @include grid-container;

    margin-top: -25px;
  }

  .figure__caption-wrapper {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
    height: 25px;
    margin-top: -25px;
    position: relative;
  }

  .figure__caption {
    color: $color-white;
    grid-column: column 1 / span 12;
    z-index: 1;

    @include typography(caption, true, false);
  }
}
