.article-cookies {
  margin: var(--margin) 0;
  position: relative;

  .article-cookies__grid {
    @include grid-container;
  }

  .article-cookies__wrapper {
    grid-column: var(--layout-full);
    margin-right: auto;
  }

  .article-cookies__title {
    @include typography(heading-3, true, true);
  }

  .article-cookies__text {
    @include typography(paragraph, true, true, true);
  }

  .article-cookies__buttons {
    margin-top: 20px;
  }
}
