
:root {
  --heading-4-font-size: 18px;
  --heading-4-font-stack: #{$ff-meta-comp-pro-font-stack};
  --heading-4-font-style: normal;
  --heading-4-font-variant: normal;
  --heading-4-font-weight: #{get-ff-meta-comp-pro-weight(bold)};
  --heading-4-letter-spacing: -.001em;
  --heading-4-line-height: 1.35em;
  --heading-4-margin-bottom: .5em;
  --heading-4-margin-top: 1.5em;

  @include portrait {
    --heading-4-font-size: 20px;
    --heading-4-line-height: 1.3em;
  }

  @include landscape {
    --heading-4-font-size: 24px;
    --heading-4-line-height: 1.25em;
  }
}
