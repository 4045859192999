
// Base colors
$color-black: #000;
$color-white: #fff;

// Theme colors
$color-pink: #fd5ea2;
$color-blue: #1dc2d9;
$color-green: #39e492;
$color-blue-dark: #00338d;
$color-grey-light: #f3f3f3;
$color-grey-medium: #e6e3e3;
$color-orange: #f79727;

// Social media colors
$color-twitter: #1da1f2;
$color-facebook: #4267b2;
$color-linkedin: #2867b2;

// Darkened theme colors
$color-pink-dark: #f2026a;
$color-green-dark: #16a25e;

// Greyscale colors
$color-grey-extradark: #3c3c3c;
$color-grey-dark: #696969;
$color-grey-extralight: #fafafa;

// Functional colors
$color-error: #ff4735;

// Transparencies
$transparency-overlay: rgba($color-white, .8);

// Beziers
$bezier-fancy: cubic-bezier(.26, .88, .57, .9);

// Easings
$easing-change: .15s $bezier-fancy;
$easing-change-slower: .35s $bezier-fancy;
$easing-change-faster: .05s $bezier-fancy;
$easing-change-extraslow: 1s $bezier-fancy;
$easing-linear: .15s linear;

// Layers / z-indexes
$z-index-hide: -1;
$z-index-hit-target: 100;
$z-index-form-field-button: 10;
$z-index-menu-overlay: 2048;

// Text shadows
//$text-shadow-on-photo: 1px 1px 15px rgba(0, 0, 0, .15);

// Exposed variables
:root {
  // None yet
  //--bezier-fancy: $bezier-fancy;
}
