
.vacancy-header {
  color: $color-black;
  padding-top: var(--margin);
  position: relative;

  .vacancy-header__grid {
    @include grid-container;
  }

  .vacancy-header__wrapper {
    grid-column: column 1 / span 12;
  }

  .vacancy-header__title {
    @include typography(heading-1);

    font-size: 40px;
    hyphens: auto;
    margin-bottom: 30px;
    word-wrap: break-word;

    @include portrait {
      font-size: 52px;
    }

    @include landscape {
      font-size: 80px;
    }
  }

  .vacancy-header__pre-title {
    @include typography(heading-4);

    max-width: 28em;
  }

  .vacancy-header__post-title {
    @include typography(heading-4);

    margin-top: calc(var(--paragraph-margin-top) / 2);
    max-width: 32em;
  }

  .vacancy-header__header {
    &:not(:last-child) {
      margin-bottom: calc(var(--paragraph-margin-bottom) * 2);
    }
  }

  .vacancy-header__data {
    margin-bottom: 30px;
  }

  .vacancy-header__data-item {
    @include typography(heading-6);

    color: $color-black;
    display: block;
    margin-right: 20px;
  }

  .vacancy-header__data-item span {
    vertical-align: middle;
  }

  .vacancy-header__buttons {
    font-size: 0;

    .button {
      margin: 20px 20px 0 0;
    }
  }
}

// .vacancy-header--height-auto {
// }

.vacancy-header--height-large {
  display: flex;
  flex-direction: row;
  min-height: 61.8vh;
  padding-bottom: var(--margin);

  .vacancy-header__grid {
    align-items: center;
    flex: 0 1 100%;
  }
}

.vacancy-header--height-small {
  display: flex;
  flex-direction: row;
  min-height: 23.6vh;
  padding-bottom: var(--margin);

  .vacancy-header__grid {
    align-items: flex-end;
    flex: 0 1 100%;
  }
}
