.menu-bar {
  padding: 20px 0;
  width: 100%;
  z-index: 1;

  .home & {
    background-color: $color-white;
  }

  .menu-bar__grid {
    @include grid-container;

    padding-left: 0;
    padding-right: 0;
  }

  .menu-bar__wrapper {
    display: flex;
    flex-direction: row;
    grid-column: column 1 / span 12;
  }

  .menu-bar__link {
    align-self: center;
    display: inline-block;
    flex-basis: 100%;
    text-align: right;

    a {
      color: $color-white;
    }

    .link__icon svg {
      fill: $color-blue-dark;
    }

    .link__span {
      color: $color-blue-dark;
      margin-right: 20px;
    }

    .link__icon--after {
      display: none;
    }

    @include mobile {
      .link__span {
        font-size: 14px;
        line-height: 28px;
      }

      .link__icon {
        padding: .3em .6em .2em .2em;
      }
    }
  }

  .menu-bar__logo .logo {
    margin-left: 20px;
    width: 175px;
  }

  @include portrait {
    .menu-bar__logo .logo {
      width: 350px;
    }
  }

  .menu-bar__button {
    align-self: center;
    display: none;
    margin-left: auto;
  }

  .logo svg g {
    fill: $color-blue-dark;
  }
}
