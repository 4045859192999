
:root {
  --heading-2-font-size: 22px;
  --heading-2-font-stack: #{$ff-meta-comp-pro-font-stack};
  --heading-2-font-style: normal;
  --heading-2-font-variant: normal;
  --heading-2-font-weight: #{get-ff-meta-comp-pro-weight(bold)};
  --heading-2-letter-spacing: -.005em;
  --heading-2-line-height: 1.25em;
  --heading-2-margin-bottom: .5em;
  --heading-2-margin-top: 1em;

  @include portrait {
    --heading-2-font-size: 28px;
    --heading-2-line-height: 1.15em;
  }

  @include landscape {
    --heading-2-font-size: 28px;
    --heading-2-line-height: 1.15em;
  }
}
