.article-form {
  margin: var(--margin) 0;
  position: relative;

  .article-form__grid {
    @include grid-container;
  }

  .article-form__wrapper {
    grid-column: var(--layout-full);
  }

  @include landscape {
    .article-form__wrapper {
      grid-column: column 2 / span 10;
      max-width: 650px;
    }
  }
}
