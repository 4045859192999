
@mixin cookie-bar-layout {
  position: relative;

  .cookie-bar__grid {
    @include grid-container;

    padding-left: var(--grid-gutter);
    padding-right: var(--grid-gutter);
  }

  .cookie-bar__bar-wrapper,
  .cookie-bar__categories {
    grid-column: var(--layout-full);
  }

  .cookie-bar__bar,
  .cookie-bar__details {
    bottom: 0;
    height: auto;
    left: 0;
    padding: var(--grid-spacing) 0;
    position: fixed;
    width: 100%;
  }

  .cookie-bar__bar {
    z-index: 9998;
  }

  .cookie-bar__details {
    z-index: 9999;

    .cookie-bar__grid {
      padding-top: 20px;
    }
  }

  .cookie-bar__bar-wrapper {
    display: flex;
    flex-direction: row;
    padding: 30px 0 0;

    &:first-child {
      padding-top: 0;
    }
  }

  .cookie-bar__text-container,
  .cookie-bar__buttons {
    transform: translate3d(0, 0, 0); // iOS 12 text visibility bug
  }

  .cookie-bar__buttons,
  .cookie-bar__details-buttons {
    white-space: nowrap;
  }

  .cookie-bar__buttons {
    .link--reject .link__span {
      line-height: 60px;
      margin-right: 20px;
      text-decoration: underline;
      text-transform: uppercase;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    .button--default {
      border-radius: 0;
      margin: 0;
      padding: 0;

      .button__span {
        height: 60px;
        line-height: 60px;
        text-transform: uppercase;
      }
    }

    .button--primary {
      background-color: transparent;
      border: 1px solid $color-black;
      border-radius: 0;
      color: $color-black;
      margin-bottom: 10px;
      padding: 10px 20px;

      .button__span {
        text-transform: uppercase;
      }

      .button__icon--after {
        height: 60px;
        margin: 0 10px 0 0;
        padding: 0;
        width: 40px;
      }
    }
  }

  .option-field {
    padding-bottom: 10px;
  }

  .cookie-bar__text-container {
    width: 100%;

    .text__span,
    .link {
      margin-right: 10px;
    }

    .link--underline .link__span {
      text-decoration: underline;
      text-transform: uppercase;
    }
  }

  .cookie-bar__categories {
    display: inline;
  }

  .cookie-bar__category {
    grid-column: auto / span 12;
    padding-bottom: 20px;

    @include notebook {
      grid-column: auto / span 4;
    }
  }

  @include mobile-only {
    .cookie-bar__bar-wrapper {
      flex-direction: column;
    }

    .cookie-bar__buttons {
      padding-top: 20px;

      .button {
        max-width: 260px;
        min-width: 100px;
        width: 50%;
      }
    }

    .cookie-bar__details {
      border-top: 0;
      height: 100%;
      left: 0;
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
      position: fixed;
      top: 0;
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    .cookie-bar__buttons {
      white-space: normal;

      .button {
        max-width: 100%;
        min-width: 100px;
        width: 100%;

        + .button {
          margin-top: 20px;
        }
      }
    }
  }
}

@mixin cookie-bar-styling {
  .cookie-bar__bar {
    background-color: $color-orange;
    //border-top: 3px solid $color-white;
    color: $color-black;
  }

  .cookie-bar__details {
    background-color: $color-orange;
    //border-top: 3px solid $color-black;
    color: $color-black;
  }

  .cookie-bar__title {
    @include typography(heading-4);
  }

  .cookie-bar__text,
  .cookie-bar__category-text,
  .cookie-bar__text .link {
    @include typography(caption);
  }

  .cookie-bar__category-text {
    @include disable-user-select;

    cursor: pointer;
    display: inline-block;
    padding-left: calc(var(--form-field-line-height) + var(--paragraph-line-height));
  }

  .cookie-bar__text,
  .cookie-bar__links {
    .link {
      .link__span {
        margin-left: 0;
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .cookie-bar__text-container {
    .cookie-bar__text {
      max-width: 50em;
    }
  }

  .link {
    display: inline;
  }
}

@mixin cookie-bar-display-logic {
  .cookie-bar__bar,
  .cookie-bar__details {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 32px, 0);
    transition: opacity $easing-change, transform $easing-change;
  }

  .cookie-bar__bar:target,
  .cookie-bar__bar--visible,
  .cookie-bar__details--visible {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0, 0, 0);
  }
}

.cookie-bar {
  @include cookie-bar-layout;
  @include cookie-bar-styling;
  @include cookie-bar-display-logic;
}
