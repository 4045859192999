
.news-card {
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-width: 280px;
  overflow: hidden;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  width: 100%;

  .card__image {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .card__content {
    background-color: $color-white;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    position: relative;
    transition: background-color $easing-change, color $easing-change;
  }

  .card__title {
    @include typography(heading-4);

    color: $color-blue-dark;
    font-weight: normal;
    margin-bottom: var(--paragraph-margin-bottom);
  }

  .card__date {
    @include typography(paragraph);

    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: var(--paragraph-margin-bottom);
  }

  .card__text {
    @include typography(paragraph);

    flex: 1 1 auto;
    font-size: 16px;
    line-height: 22px;
  }

  .card__video {
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    .card__video-play {
      background-color: $color-blue-dark;
      color: $color-white;
      display: inline-block;
      height: 50px;
      left: 50%;
      margin: 0;
      padding: 12px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50px;

      svg {
        transition: transform .2s;
      }
    }

    .card__video-external {
      bottom: 10px;
      color: $color-white;
      display: inline;
      height: 15px;
      position: absolute;
      right: 0px;
      transform: translate(-50%, -50%);
      width: 15px;
    }

    .picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  &:hover .card__video-play svg,
  &:focus .card__video-play svg {
    transform: scale(1.2, 1.2);
  }
}
