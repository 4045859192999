
.button {
  @include typography(button);

  border: 2px solid transparent;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  max-width: min(100%, 520px);
  min-width: 60px;
  overflow: hidden;
  padding: 16px 26px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  transition: color $easing-change, background-color $easing-change, border-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: auto;

  .button__span {
    display: block;
    flex: 1 1 100%;
    height: 20px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .button__icon {
    display: block;
    flex: 0 0 var(--button-line-height);
    height: var(--button-line-height);
    padding: .3em 0em .1em;
    width: var(--button-line-height);

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }

  .button__icon--before {
    margin-left: -.5em;
    margin-right: .2em;
  }

  .button__icon--after {
    margin-left: .5em;
    margin-right: -.5em;
  }
}

.button:disabled,
.button--disabled {
  cursor: default;
  opacity: .25;
  pointer-events: none;
}

.button--primary {
  background-color: $color-white;
  color: $color-blue-dark;
  margin-top: 20px;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-blue-dark;
    color: $color-white;
  }
}

.button--primary-inverted {
  background-color: $color-blue-dark;
  color: $color-white;
  margin-top: 30px;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-white;
    color: $color-blue-dark;
  }
}

.button--secondary {
  background-color: $color-grey-light;
  border-color: $color-grey-light;
  color: $color-grey-extradark;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-grey-extradark;
    border-color: $color-grey-extradark;
    color: $color-grey-extralight;
  }
}

.button--primary-on-image {
  background-color: $color-white;
  border-color: $color-white;
  color: $color-black;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-grey-extradark;
  }
}

.button--secondary-on-image {
  background-color: $color-black;
  border-color: $color-black;
  color: $color-white;

  &:hover:not(.button--disabled):not(:disabled),
  &:focus:not(.button--disabled):not(:disabled),
  &.button--selected {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-black;
  }
}
