
:root {
  --heading-1-font-size: 35px;
  --heading-1-font-stack: #{$ff-meta-comp-pro-font-stack};
  --heading-1-font-style: normal;
  --heading-1-font-variant: normal;
  --heading-1-font-weight: #{get-ff-meta-comp-pro-weight(bold)};
  --heading-1-letter-spacing: -.01em;
  --heading-1-line-height: 1.05em;
  --heading-1-margin-bottom: .5em;
  --heading-1-margin-top: 1em;
  --heading-1-word-spacing: normal;

  @include portrait {
    --heading-1-font-size: 50px;
    --heading-1-line-height: 1.15em;
  }

  @include landscape {
    --heading-1-font-size: 50px;
    --heading-1-line-height: 1.1em;
  }
}
