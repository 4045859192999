
.link {
  @include typography(paragraph);

  color: inherit;
  display: inline-flex;
  position: relative;
  text-decoration: none;
  transition: color $easing-change;
  vertical-align: bottom;

  .link__span {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin-left: 20px;
    vertical-align: baseline;
  }

  .link__icon {
    display: inline-block;
    height: var(--paragraph-line-height);
    padding: 0em .6em .3em .2em;
    position: absolute;
    vertical-align: top;
    width: var(--paragraph-line-height);

    > svg {
      display: block;
      height: 100%;
      width: 100%;
    }

    &.link__icon--after {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      padding: .1em .4em 0;
      position: relative;
    }
  }

  &:hover,
  &:focus {
    .link__span {
      text-decoration: underline;
    }
  }
}
