
@mixin detail-image {
  display: none;

  @include landscape {
    display: block;
  }

  .detail-image__title {
    @include typography(sub-heading);

    color: $color-blue;
  }

  .detail-image__title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .detail-image__icons-container {
    min-width: 83px;
    text-align: right;
  }

  .detail-image__icon {
    color: $color-black;
    cursor: pointer;

    &:last-child {
      margin-left: 2px;
      margin-right: 0;
    }
  }

  .button-favorite {
    .heart-filled {
      display: none;
    }

    .heart-empty {
      display: block;
    }
  }

  .button-favorite--active {
    .heart-filled {
      display: block;
    }

    .heart-empty {
      display: none;
    }
  }

  button:focus {
    outline: none;
  }
}

@mixin swiper {
  .swiper-button-prev,
  .swiper-button-next,
  .image-header__expand {
    background: $color-white;
    bottom: 0;
    height: 30px;
    left: auto;
    opacity: 1;
    padding: 8px;
    position: absolute;
    top: auto;
    width: 30px;
    z-index: 500;

    svg {
      height: 15px;
      width: 15px;

      g {
        fill: $color-black;
        opacity: 1;
      }
    }
  }

  .swiper-wrapper {
    padding: 0 0 35px;
  }

  .swiper-slide {
    list-style: none;
  }

  .swiper-button-next {
    left: 30px;
  }

  .swiper-button-prev {
    left: 0;
    padding-left: 0;
  }

  .swiper-navigation {
    display: none;

    @include landscape {
      display: block;
    }
  }
}

.detail-image {
  @include detail-image;

  .swiper-container {
    @include swiper;
  }
}
