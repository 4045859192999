.video-embed {
  height: auto;
  position: relative;
  width: 100%;

  .video-embed__container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    .picture {
      height: auto;
      opacity: 1;
      padding-bottom: 56.25%;
      width: 100%;
    }
  }

  .video-embed__caption {
    @include typography(caption);
  }

  .video-embed__video,
  .video-embed__hit-target {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .video-embed__hit-target--cookie-overlay-background {
    background-color: rgba(0, 0, 0, .6);
  }

  .video-embed__cookie-overlay {
    color: $color-white;
    display: none;
    font-size: .75rem;
    margin: 3% auto 0;
    text-align: center;
    width: 80%;

    @include notebook {
      font-size: 1rem;
      margin: 10% auto 0;
    }
  }

  .video-embed__cookie-text {
    display: inline-block;
    width: 100%;
  }

  .video-embed__cookie-button {
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    width: 100%;
    z-index: 100;
  }

  .cookie-overlay--cookies-not-accepted {
    display: block;
  }

  .video-embed__video {
    background-color: $color-black;
  }

  .video-embed__hit-target[href] {
    cursor: pointer;
  }

  .video-embed__button {
    color: $color-white;
    display: none;
    font-size: 3.7rem;
    left: 50%;
    line-height: 1em;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 1em;

    @include notebook {
      font-size: 4rem;
    }

    svg {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .video-embed__iframe,
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity $easing-change-slower;
    width: 100%;
  }

  &.video-embed--loaded {
    .video-embed__hit-target {
      display: none;
    }
  }

  &:not(.video-embed--loaded) {
    .video-embed__video {
      filter: brightness(.66) contrast(1.1);
    }

    .video-embed__button--play {
      display: block;
    }
  }
}
