
// Flexible VARIABLE webfont base file for a variable weight font
// NOTE: When using this file as a base, rename all $*-font variables.

// Variable font settings
$ff-meta-variable-font-name: 'FF Meta Variable Webfont';
$ff-meta-variable-font-cleanname: 'ff-meta-variable';
$ff-meta-variable-font-path: '/static/fonts/' + $ff-meta-variable-font-cleanname + '/';
$ff-meta-variable-font-stack: $ff-meta-variable-font-name, 'Helvetica', 'Arial', sans-serif;
$ff-meta-variable-font-feature-settings: 'kern' 1, 'calt' 1, 'liga' 1, 'frac' 1, 'ss01' 1, 'ss03' 1, 'dlig' 1;
$ff-meta-variable-weight-min: 1;
$ff-meta-variable-weight-max: 999;

@font-face {
  //font-display: optional;
  font-family: $ff-meta-variable-font-name;
  font-weight: 100 900;
  src: url($ff-meta-variable-font-path + $ff-meta-variable-font-cleanname + '-roman.woff2') format('woff2-variations');
}

@font-face {
  //font-display: optional;
  font-family: $ff-meta-variable-font-name;
  font-style: oblique; // NOT italic; due to Safari<=12.1 bug
  font-weight: 100 900;
  src: url($ff-meta-variable-font-path + $ff-meta-variable-font-cleanname + '-italic.woff2') format('woff2-variations');
}

:root {
  //--font--ff-meta-variable: #{inspect($ff-meta-variable-font-stack)};

  // Font weights
  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-demibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;

  // Font styles
  --font-style-normal: normal;
  --font-style-italic: oblique;
}

$ff-meta-variable-weight-references: (
  extrathin: 1,
  thin: 100,
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  demibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
  extrablack: 999,
);

// Font mixin whereby you can use the real font weights, such as 'book' or 'black'.
@mixin font-ff-meta-variable($font-weight: null) {
  // Set the basics
  font-family: $ff-meta-variable-font-stack;
  font-feature-settings: $ff-meta-variable-font-feature-settings;
  font-size-adjust: initial;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: $font-weight;
  //font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @if ($font-weight) {
    $font-weight: get-ff-meta-variable-weight($font-weight);

    @if ($font-weight) {
      font-weight: $font-weight;
    }
  }
}

// Get a real font weight value. For example: 'black' returns 900.
@function get-ff-meta-variable-weight($font-weight) {

  // Convert possible 'normal' font-weight strings to 'regular', which is the font's actual weight name
  @if not $font-weight or $font-weight == 'normal' {
    $font-weight: 'regular';
  }

  // Look up the actual weight
  @if ($font-weight != 'normal') {
    $font-weight: map-get($ff-meta-variable-weight-references, $font-weight);
  }

  @return $font-weight;
}
