
.share-button {
  @include typography(button);

  background-color: $color-grey-light;
  border: 0;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  margin-right: 10px;
  overflow: hidden;
  padding: 6px;
  position: relative;
  transition: color $easing-change, background-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: 50px;

  &:hover,
  &:focus {
    background-color: $color-grey-extradark;
    color: $color-grey-extralight;
  }

  &.share-button-facebook {
    background-color: $color-facebook;
  }

  &.share-button-twitter {
    background-color: $color-twitter;
  }

  &.share-button-linkedin {
    background-color: $color-linkedin;
  }

  .share-button__icon {
    display: block;
    height: var(--button-height);
    padding: .1em .1em .2em;
    width: var(--button-height);

    > svg {
      display: block;
      fill: currentColor;
      height: 100%;
      width: 100%;
    }
  }
}
