
.call-to-action {
  background-color: #f3f3f3;
  margin: 0;
  padding: 60px 0;

  .call-to-action__grid {
    @include grid-container;
  }

  .call-to-action__wrapper {
    grid-column: column 1 / span 12;
  }

  p {
    @include typography(paragraph, true, true);

    margin-bottom: var(--paragraph-margin-bottom);
  }

  &.clean {
    background: none;
    padding: 0;
  }
}
