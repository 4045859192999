
.logo {
  color: inherit;
  display: inline-block;
  height: 100px;
  line-height: 0;
  position: relative;
  transition: color $easing-change;
  user-select: none;
  vertical-align: top;
  width: 350px;

  svg g {
    fill: $color-white;
  }

  .logo__svg {
    display: block;
    height: 100%;
    width: 100%;

    path {
      fill: currentColor;
      transition: fill $easing-change;
    }
  }
}
