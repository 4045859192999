.file-field {
  position: relative;

  .file-field__label {
    @include typography(paragraph, false, true, false);

    cursor: pointer;
    display: block;
    font-weight: 400;
    line-height: 32px;
    margin: 8px 0;
    user-select: none;
  }

  .file-field__wrapper {
    font-size: 0;
    max-width: 100%;
    position: relative;

    .file-field__filename {
      color: $color-black;
      font-size: 14px;
      margin: 13px;
      padding-right: 10px;

      .file-field__filename_icon {
        cursor: pointer;
        margin-left: 0;
        padding-left: 0;

        li {
          align-items: center;
          display: flex;
          list-style: none;
          word-break: break-all;
        }

        .icon-close {
          margin-right: 6px;
          transform: translateY(2px);
          width: 10px;
        }
      }
    }
  }

  .file-field__label_button {
    align-items: center;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%), 0px 0px 10px rgb(0 0 0 / 20%);
    display: inline-flex;

    .button__icon {
      transform: translateY(-2px);
    }
  }

  .file-field__input {
    clip: rect(1px, 1px, 1px, 1px);
    color: $color-white;
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;

    &:disabled {
      + .button {
        opacity: .4;
        pointer-events: none;
      }
    }

    &:not(:disabled) {
      &:focus {
        + label {
          border: 1px solid $color-white;
        }

        + .button {
          border-color: color(black);
        }
      }
    }
  }

  &.file-field--required {
    .file-field__label {
      &::after {
        content: '*';
        display: inline-block;
        margin-inline-start: .25em;
      }
    }
  }

  &:not(.file-field--required) {
    .file-field__label {
      &::after {
        content: '';
        display: inline-block;
        margin-inline-start: .25em;
      }
    }
  }

  .file-field__label-icon {
    display: none;
  }

  &.file-field--error {
    .file-field__label {
      align-items: center;
      color: #d52b1e;
      display: inline-flex;
      font-weight: 500;

      .file-field__label-icon {
        display: block;
        margin-right: .3125rem;
        transform: translateY(1px);
        width: 19px;
      }
    }

    .file-field__error {
      @include typography(caption);

      background-color: #f9dfdd;
      display: inline-block;
      font-size: 16px;
      max-width: 100%;
      padding: 15px 20px;
      width: 100%;
    }
  }
}
