
:root {
  --paragraph-font-size: 20px;
  --paragraph-font-stack: #{$ff-meta-comp-pro-font-stack};
  --paragraph-font-style: normal;
  --paragraph-font-variant: normal;
  --paragraph-font-weight: #{get-ff-meta-comp-pro-weight(light)};
  --paragraph-letter-spacing: 0;
  --paragraph-line-height: 1.45em;
  --paragraph-margin-bottom: .5em;
  --paragraph-margin-top: 1em;

  @include portrait {
    --paragraph-font-size: 22px;
    --paragraph-line-height: 1.4em;
  }

  @include landscape {
    --paragraph-font-size: 22px;
    --paragraph-line-height: 1.4em;
  }
}
