.form {
  position: relative;
  width: 100%;

  .form__title {
    @include typography(heading-1, false, true);
  }

  .form__text {
    @include typography(introduction, true, true);

    &.form__text--error {
      @include typography(paragraph);

      background-color: #f9dfdd;
      border-left: 4px solid #d52b1e;
      color: $color-black;
      font-size: 16px;
      font-weight: 400;
      padding: 20px;
    }
  }
}
