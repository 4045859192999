
.footer-bar {
  position: relative;

  .footer-bar__top {
    background-color: $color-white;
    color: $color-black;
    padding: var(--margin) 0;
  }

  .footer-bar__bottom {
    background-color: $color-orange;
    color: $color-blue-dark;
    padding: var(--margin) 0;
  }

  .footer-bar__wrapper {
    @include grid-container;
  }

  .footer-bar__grid {
    @include grid-container;

    grid-auto-rows: minmax(min-content, max-content);
  }

  .footer-bar__subscribe {
    grid-column: column 1 / span 4;
  }

  .footer-bar__subscribe-title {
    @include typography(heading-2, true, true);
  }

  .footer-bar__subscribe-text {
    @include typography(paragraph, true, true);
  }

  .footer-bar__follow {
    align-self: flex-end;
    font-size: 0;
    grid-column: column 1 / span 4;
    text-align: left;

    .icon-button {
      + .icon-button {
        margin-left: 5px;
      }
    }
  }

  .footer-bar__logo {
    grid-column: column 1 / span 4;
  }

  .footer-bar__social {
    grid-column: column 1 / span 12;
  }

  .footer-bar__column {
    margin-bottom: var(--margin / 2);

    .link {
      margin-bottom: 0;
    }
  }

  .footer-bar__column:nth-of-type(1) {
    grid-column: column 1/span 6;
  }

  .footer-bar__column:nth-of-type(2) {
    grid-column: column 7/span 6;
  }

  @include landscape {
    .footer-bar__column:nth-of-type(1) {
      grid-column: column 7/span 3;
    }

    .footer-bar__column:nth-of-type(2) {
      grid-column: column 10/span 3;
    }
  }

  .footer-bar__contact-details {
    @include typography(caption, true, true);

    .footer-bar__phone {
      margin-top: 30px;
    }
  }

  @include landscape {
    .footer-bar__follow {
      grid-column: column 3 / span 4;
      text-align: right;
    }

    .footer-bar__logo {
      grid-column: column 1 / span 2;
    }
  }
}
