
.article-quote {
  margin: var(--margin) 0;
  position: relative;

  .article-quote__grid {
    @include grid-container;
  }

  .article-quote__wrapper {
    grid-column: column 1 / span 12;
    margin-left: auto;
    margin-right: auto;
  }

  .article-quote__text {
    @include typography(heading-1);

    font-weight: 200;
    position: relative;
    text-align: left;
  }

  .article-quote__attribution {
    @include typography(paragraph);

    display: inline-block;
    float: left;
    grid-column: column 1 / span 12;
    margin: 20px 0 0;
    text-align: left;
    vertical-align: top;

    @include landscape {
      grid-column: column 2 / span 11;
    }
  }

  .article-quote__attribution-name {
    font-size: 16px;
    font-weight: var(--font-weight-normal);
    line-height: 22px;
  }

  .article-quote__attribution-text {
    font-style: oblique;
  }
}
