
.article-bulletlist {
  background-color: $color-grey-light;
  margin: var(--margin) 0;
  margin: 0 0 2px;
  padding: 60px 0;
  position: relative;

  .article-bulletlist__grid {
    @include grid-container;
  }

  .article-bulletlist__titlewrapper {
    grid-column: column 1/span 12;
  }

  .article-bulletlist__leftwrapper {
    grid-column: column 1/span 12;
  }

  .article-bulletlist__rightwrapper {
    grid-column: column 1/span 12;
  }

  .article-bulletlist__fullwrapper {
    grid-column: column 1/span 12;
  }

  @include notebook {
    .article-bulletlist__titlewrapper {
      grid-column: column 3/span 8;
    }

    .article-bulletlist__leftwrapper {
      grid-column: column 3/span 4;
    }

    .article-bulletlist__rightwrapper {
      grid-column: column 7/span 4;
    }

    .article-bulletlist__fullwrapper {
      grid-column: column 3/span 8;
    }
  }

  h3 {
    @include typography(heading-3, true, true);

    margin-bottom: var(--heading-3-margin-bottom);
  }

  .article-bulletlist__fullwrapper ul {
    @include style-list-base;
    @include style-list-unordered;

    li {
      @include typography(paragraph, false, false);
      @include adaptive-margin(calc(var(--paragraph-margin-top) * .5), calc(var(--paragraph-margin-bottom) * .5));
    }
  }

  .article-bulletlist__leftwrapper ul,
  .article-bulletlist__rightwrapper ul, {
    float: left;
    list-style: none;
    margin-bottom: calc(var(--paragraph-margin-top) * .5);
    padding: 0;

    li {
      @include typography(paragraph, false, false);
      @include adaptive-margin(calc(var(--paragraph-margin-top) * .5), calc(var(--paragraph-margin-bottom) * .5));

      &::before {
        background-image: url('../../../static/images/checkmark.svg');
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 50%;
        content: '';
        display: inline-block;
        height: 1em;
        margin-left: 0px;
        padding-left: 1.6em;
        width: 1em;
      }
    }
  }
}
