
.pagination {
  align-items: center;
  display: inline-block;
  font-size: 0;
  height: 40px;
  position: relative;
  text-align: center;

  .number-button {
    margin: 0 5px;
  }

  .number-button--selected,
  .number-button--dots {
    pointer-events: none;
  }

  .number-button--selected {
    background-color: $color-black;
    color: $color-white;
  }
}
