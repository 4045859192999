
.article-content {
  margin: var(--margin) 0;
  position: relative;

  .article-content__grid {
    @include grid-container;
  }

  .article-content__wrapper {
    grid-column: var(--layout-full);
    margin-right: auto;
  }
}

.article-content--narrow {
  .article-content__grid {
    @include grid-container;
  }

  .article-content__wrapper {
    grid-column: column 1 / span 12;
    margin-right: auto;
  }

  @include notebook {
    .article-content__wrapper {
      grid-column: column 3 / span 8;
    }
  }
}

.article-content--grey {
  background-color: $color-grey-light;
  margin: 0;
  padding: 60px 0;

  &:last-of-type {
    margin-bottom: 30px;
  }
}

.article-content--narrow-grey {
  background-color: $color-grey-light;
  margin: 0 0 2px;
  padding: 60px 0;

  .article-content__grid {
    @include grid-container;
  }

  .article-content__wrapper {
    grid-column: column 1 / span 12;
    margin-right: auto;
  }

  @include notebook {
    .article-content__wrapper {
      grid-column: column 3 / span 8;
    }
  }
  //Replace later
  ul.checkmark {
    float: left;
    list-style: none;

    li {
      &::before {
        background-image: url('../../../static/images/checkmark.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: inline-block;
        height: 1em;
        margin-right: .5em;
        width: 1em;
      }
    }
  }

  ul.checkmark:nth-of-type(2) {
    margin-left: 100px;
  }
}
